import { ChangeDetectorRef, Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentTypes, EmployeesService } from '../../dashboard/services/employees.service';
import { Observable, ReplaySubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RoleEnum } from '../../shared/models';
import { environment } from '../../../environments/environment';
import { EnvironmentEnum } from '../../shared/enums/app.enum';
import { MaskDirective } from '../../shared/directives/mask.directive';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { PermissionEmployeeEnum, PermissionMedicalApplicationEnum } from '../../shared/enums/permission.enum';
import { PermissionAccessService } from '../../shared/services/permission-access.service';
import { MedicalService } from '../../dashboard/services/medical.service';
import { arraysHaveSameElements } from '../../shared/helpers/other';
import { MessagePopupComponent } from '../message-popup/message-popup.component';
import { PopupFactoryService } from '../popup-factory.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PHONE_MASK } from '../../shared/helpers/phone-mask';

export interface DocumentPopupActions {
  sign: (docIds: number[] | string[], allDocs: boolean, isModifiesDocsList?: boolean) => Observable<any>;
  print: (docIds: number[] | string[], allDocs: boolean, isModifiesDocsList?: boolean) => Observable<any>;
  text: (docIds: number[] | string[], allDocs: boolean, phone: string, isModifiesDocsList?: boolean) => Observable<any>;
  email: (docIds: number[] | string[], allDocs: boolean, isModifiesDocsList?: boolean, email?: string, idEBC?: number) => Observable<any>;
}

enum DisabledDocKey {
  TestFolder = 'test_folder_v2',
  PersonnelContractorRecordsChecklistV2 = 'personnel_contractor_records_checklist_v2',
  EBC = 'employee_background_check_result',
}

@Component({
  selector: 'app-choosed-documents',
  templateUrl: './choose-documents.component.html',
  styleUrls: ['./choose-documents.component.scss'],
})
export class ChooseDocumentsComponent implements OnInit, OnDestroy {
  @Input() actions: Partial<DocumentPopupActions>;
  @Input() mode: 'full' | 'offer' = 'full';
  @Input() isEditDocument = false;
  @Input() preselectedDocs: any[];
  @Input() newAppForm = false;
  @Input() isUserTpl = false;
  @Input() listOfSendingDoc: any[] = [];
  @Input() idEmployee: string;
  @Input() idMedical: string;
  @Input() withoutId = false;
  @Input() errorSend: any = {};
  @Input() isDisableSendAction = false;
  @Input() eSignature = false;
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() selDocuments: EventEmitter<DocumentTypes[]> = new EventEmitter();
  next = false;
  documents: DocumentTypes[] = null;
  testDocuments: DocumentTypes[] = null;
  allSelected = false;
  allTestSelected = false;
  activePhoneField = false;
  activeEmailField = false;
  phone = '';
  emailSend = '';
  isClickSend = false;
  phoneMeta = {
    error: null,
    success: false,
  };
  filter = '';
  phoneMask = PHONE_MASK;
  errors: any[] = [];
  blockRequest = false;
  exceptDocs: string[] = ['ppd_questionnaire', 'two_step_tb_form', 'tb_risck_assesment'];
  defaultSelectedDocs: number[] = [];
  isModifiesDocsList = false;
  RoleEnum = RoleEnum;
  DisabledDocKey = DisabledDocKey;
  idEBC: number;
  dynamicTestAmount = 0;
  protected readonly PermissionEmployeeEnum = PermissionEmployeeEnum;
  private destroy$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private employeeService: EmployeesService,
    private cd: ChangeDetectorRef,
    private permissionService: PermissionAccessService,
    private medicalSevice: MedicalService,
    private popup: PopupFactoryService,
  ) {}

  @Input() set enabledIsClickSend(value: boolean) {
    if (value) {
      this.isClickSend = false;
    }
  }

  get selectedDocs(): DocumentTypes[] {
    const allDocs: DocumentTypes[] = [...(this.documents || []), ...(this.testDocuments || [])];
    if (allDocs?.length === 1) {
      allDocs[0].checked = true;
    }

    return allDocs.filter((doc: DocumentTypes) => doc.checked) || [];
  }

  get phoneValue(): string {
    return this.phone.replace(/-/g, '').replace(/_/g, '').substring(0, 12);
  }

  public get checkEBC(): string {
    if (this.idMedical) {
      return 'withoutEBC';
    } else {
      const docs = this.selectedDocs.map(doc => doc.id);
      if (docs.includes(this.idEBC)) {
        if (docs.length === 1) {
          return 'onlyEBC';
        } else {
          return 'withEBC';
        }
      } else {
        return 'withoutEBC';
      }
    }
  }

  ngOnInit(): void {
    this.getDocumentTypes();
  }

  ngOnDestroy(): void {
    this.isClickSend = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectAll(): void {
    this.allSelected = !this.allSelected;
    this.allTestSelected = true;
    this.documents = this.documents?.map((doc: DocumentTypes) => {
      if (!doc.isDisabled) {
        return { ...doc, checked: this.allSelected };
      } else {
        return { ...doc, checked: false };
      }
    });
    this.testDocuments = this.testDocuments?.map((doc: DocumentTypes) => {
      if (!doc.isDisabled) {
        return { ...doc, checked: this.allSelected };
      } else {
        return { ...doc, checked: false };
      }
    });
    if (!this.allSelected) {
      this.clearTestSelected();
    }
    if (this.isUserTpl) {
      const data: DocumentTypes[] = this.allSelected ? this.documents : [];
      this.emitSelectedDocuments(data);
    }
  }

  selectTestAll(): void {
    this.allTestSelected = !this.allTestSelected;
    this.testDocuments = this.testDocuments?.map((doc: DocumentTypes) => {
      if (!doc.isDisabled) {
        return { ...doc, checked: this.allTestSelected };
      } else {
        return { ...doc, checked: false };
      }
    });
    this.setAllSelected();
    if (this.isUserTpl) {
      const data: DocumentTypes[] = this.allTestSelected ? this.testDocuments || [] : [];
      this.emitSelectedDocuments(data);
    }
  }

  toggleTestDoc(index: number) {
    this.testDocuments[index].checked = !this.testDocuments[index].checked;
    this.allTestSelected = this.testDocuments.filter((doc: DocumentTypes) => doc.checked).length === this.testDocuments.length;
    this.setAllSelected();
    this.allTestSelected =
      this.testDocuments.filter((item: DocumentTypes) => item.isTestFolder !== true).filter((doc: DocumentTypes) => doc.checked).length ===
      this.testDocuments.length - 1;
    if (this.isUserTpl) {
      this.emitSelectedDocuments(this.selectedDocs);
    }
  }

  toggle(index: number): void {
    this.documents[index].checked = !this.documents[index].checked;
    this.setAllSelected();
    const haveOneChecked = this.documents?.some((doc: DocumentTypes) => doc.checked === true) || false;
    if (!haveOneChecked) {
      this.clearTestSelected();
    }
    if (this.isUserTpl) {
      this.emitSelectedDocuments(this.selectedDocs);
    }
    if (
      (this.selectedDocs.length === 1 && this.selectedDocs[0].key === DisabledDocKey.TestFolder) ||
      (this.selectedDocs.length === 1 && this.selectedDocs[0].key === DisabledDocKey.PersonnelContractorRecordsChecklistV2) ||
      (this.selectedDocs.length === 1 && this.selectedDocs[0].key === DisabledDocKey.EBC && !this.eSignature)
    ) {
      this.selectedDocs[0].checked = false;
      this.emitSelectedDocuments(this.selectedDocs);
    }
  }

  chooseAction(action: string): void {
    if (this.actions && this.actions[action]) {
      const docIds: any = this.selectedDocs.map((item: DocumentTypes) => (this.idMedical ? item.key : item.id));
      this.isModifiesDocsList = !arraysHaveSameElements(this.defaultSelectedDocs, docIds);
      if (action === 'text') {
        this.isClickSend = true;
        this.phoneMeta = { ...this.phoneMeta, error: false };
        if (this.blockRequest) {
          return;
        }
        this.blockRequest = true;
        const phone: string = this.phone.match(/\d+/g).join('').substring(0, 11);
        this.actions
          .text(docIds, this.allSelected, phone, this.isModifiesDocsList)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.blockRequest = false;
              this.isClickSend = false;
              this.phoneMeta = { ...this.phoneMeta, success: true };
              this.cd.detectChanges();
              setTimeout(() => {
                this.activePhoneField = false;
                this.phoneMeta = { error: null, success: false };
                this.cd.detectChanges();
              }, 3000);
              if (this.checkEBC !== 'withoutEBC') {
                this.popup
                  .createPopup({
                    popupComponent: MessagePopupComponent,
                    preventBgClick: true,
                    popupData: {
                      message: 'Date change will be applied in 10 seconds',
                      yellowHeader: true,
                      closeRoute: null,
                    },
                  })
                  .subscribe();
              }
            },
            error: (err: any) => {
              this.isClickSend = false;
              this.blockRequest = false;
              this.phoneMeta = { ...this.phoneMeta, error: Object.values(err.error.violations || {}).join(' ') };
              this.cd.detectChanges();
            },
          });
      }
      if (action === 'email' && this.idMedical) {
        this.isClickSend = true;
        this.phoneMeta = { ...this.phoneMeta, error: false };
        if (this.blockRequest) {
          return;
        }
        this.blockRequest = true;
        this.actions
          .email(docIds, this.allSelected, false, this.emailSend)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.blockRequest = false;
              this.isClickSend = false;
              this.phoneMeta = { ...this.phoneMeta, success: true };
              this.cd.detectChanges();
              setTimeout(() => {
                this.activePhoneField = false;
                this.phoneMeta = { error: null, success: false };
                this.cd.detectChanges();
              }, 3000);
            },
            error: (err: any) => {
              this.isClickSend = false;
              this.blockRequest = false;
              this.phoneMeta = { ...this.phoneMeta, error: Object.values(err.error.violations || {}).join(' ') };
              this.cd.detectChanges();
            },
          });
      } else {
        if (this.blockRequest) {
          return;
        }
        this.blockRequest = true;
        if (!this.newAppForm) {
          this.isClickSend = true;
          this.actions[action](docIds, this.allSelected, this.isModifiesDocsList, '', this.idEBC)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              () => {
                this.blockRequest = false;
                this.isClickSend = false;
                this.cd.detectChanges();
                this.closed.emit();
              },
              (err: any) => {
                this.blockRequest = false;
                this.isClickSend = false;
                this.errors = Object.values(err.error.violations || {});
                this.cd.detectChanges();
              },
            );
        } else {
          this.isClickSend = true;
          this.actions[action](docIds, this.allSelected, this.isModifiesDocsList, '', this.idEBC)
            .pipe(
              finalize(() => {
                this.blockRequest = false;
                this.isClickSend = false;
                this.cd.detectChanges();
              }),
              takeUntil(this.destroy$),
            )
            .subscribe();
          this.blockRequest = false;
        }
      }
    }
  }

  public removeError(name): void {
    delete this.errorSend[name];
    if (!this.errorSend || (this.errorSend && !Object.keys(this.errorSend || {})?.length)) {
      this.isDisableSendAction = false;
    }
  }

  toggleStep(): void {
    this.filter = '';
    if (this.documents.filter((doc: DocumentTypes) => doc.checked).length) {
      this.next = !this.next;
    }
  }

  showDoc(docName): boolean {
    return this.filter === '' || docName.toLowerCase().includes(this.filter.toLowerCase());
  }

  public hasPermissionPrint(): Observable<boolean> {
    if (this.idMedical) {
      return this.permissionService.hasAllPermissions([PermissionMedicalApplicationEnum.ApproveMedicalApplication]);
    } else {
      return this.permissionService.hasAllPermissions([PermissionEmployeeEnum.ApproveEmployee, PermissionEmployeeEnum.DownloadDocuments]);
    }
  }

  private setAllSelected() {
    this.allSelected = [...(this.documents || []), ...(this.testDocuments || [])]
      ?.filter((doc: DocumentTypes) => !doc.isDisabled)
      ?.every((doc: DocumentTypes) => doc.checked);
  }

  private clearTestSelected() {
    this.testDocuments = this.testDocuments?.map((doc: DocumentTypes) => {
      return { ...doc, checked: false };
    });
    this.allTestSelected = false;
  }

  private emitSelectedDocuments(documents: DocumentTypes[]): void {
    this.selDocuments.emit(documents);
  }

  private setTestDocuments(data: DocumentTypes[]): void {
    this.testDocuments = data
      .filter((item: DocumentTypes) => item.isDynamicTest === true || item.isTestFolder === true)
      .sort((a, b) => {
        if (a.name === 'Test folder') return -1;
        if (b.name === 'Test folder') return 1;
        return a.name.localeCompare(b.name);
      });
  }

  private getDocumentTypes(): void {
    if (this.idEmployee || this.withoutId) {
      this.employeeService
        .getDocumentTypes(this.idEmployee, this.withoutId)
        .pipe(
          take(1),
          tap((docs: DocumentTypes[]) => {
            this.idEBC = docs.find(doc => doc.key === DisabledDocKey.EBC).id;
          }),
        )
        .subscribe({
          next: (data: DocumentTypes[]) => {
            if (this.mode === 'full') {
              this.setTestDocuments(data);
              this.documents = data
                .filter((item: DocumentTypes) => !this.testDocuments.includes(item))
                .map((item: DocumentTypes) => ({
                  ...item,
                  checked: false,
                }));
              if (this.preselectedDocs) {
                this.preselectedDocs.forEach((doc: any) => {
                  if (doc.key === 'annual_competency_perform_eval_checklist') {
                    this.documents.push({ ...doc, checked: false });
                  }
                });
              }
              if (environment.client === EnvironmentEnum.Aac) {
                this.documents = this.isEditDocument
                  ? this.documents.filter((item: DocumentTypes): boolean => item.key !== 'orientation_checklist_for_staff')
                  : this.documents.filter(
                      (item: DocumentTypes) =>
                        item.key !== 'orientation_checklist_for_staff' && item.key !== 'annual_competency_perform_eval_checklist',
                    );
              } else {
                this.documents = this.isEditDocument
                  ? this.documents
                  : this.documents.filter((item: DocumentTypes): boolean => item.key !== 'annual_competency_perform_eval_checklist');
              }
            } else {
              this.documents = data.filter((item: DocumentTypes) => item.name.toLowerCase().includes('offer'));
              this.toggle(0);
              this.toggleStep();
            }

            if (this.listOfSendingDoc.length) {
              this.documents.forEach((elementDoc: DocumentTypes) => {
                this.listOfSendingDoc.forEach(docFromSMS => {
                  if (elementDoc.id === docFromSMS.id) {
                    elementDoc.checked = docFromSMS.checked;
                  }
                });
              });
              this.allSelected =
                this.documents.filter((item: DocumentTypes) => !this.exceptDocs.includes(item.key)).filter((doc: DocumentTypes) => doc.checked)
                  .length ===
                this.documents.length - this.exceptDocs.length;
            }

            if (this.preselectedDocs && this.preselectedDocs.length) {
              this.preselectedDocs.forEach((doc: DocumentTypes) => {
                const index: number = this.documents.findIndex((document: DocumentTypes): boolean => document.id === doc.id);
                if (this.documents[index]) {
                  this.toggle(index);
                }
              });
              this.toggleStep();
            }
            this.defaultSelectedDocs = this.selectedDocs.map((doc: DocumentTypes) => doc.id);
            this.cd.detectChanges();

            this.dynamicTestAmount = this.documents.filter(i => i.isDynamicTest).length;
          },
          error: () => {
            this.documents = [];
            this.testDocuments = [];
            this.dynamicTestAmount = 0;
          },
        });
    }
    if (this.idMedical) {
      this.medicalSevice
        .getDocumentTypes(this.idMedical)
        .pipe(take(1))
        .subscribe({
          next: (data: DocumentTypes[]) => {
            this.setTestDocuments(data);
            this.documents = data
              .filter((item: DocumentTypes) => !this.testDocuments.includes(item))
              .map((item: DocumentTypes) => ({ ...item, checked: false }));

            if (this.preselectedDocs && this.preselectedDocs.length) {
              this.preselectedDocs.forEach((doc: DocumentTypes) => {
                const index: number = this.documents.findIndex((document: DocumentTypes): boolean => document.id === doc.id);
                if (this.documents[index]) {
                  this.toggle(index);
                }
              });
              this.toggleStep();
            }
            this.cd.detectChanges();

            this.dynamicTestAmount = this.documents.filter(i => i.isDynamicTest).length;
          },
          error: () => {
            this.documents = [];
            this.dynamicTestAmount = 0;
          },
        });
    }
  }
}

@NgModule({
  declarations: [ChooseDocumentsComponent],
  imports: [NgScrollbarModule, CommonModule, FormsModule, ReactiveFormsModule, MaskDirective, SharedModule],
  exports: [ChooseDocumentsComponent],
  providers: [],
})
export class ChooseDocumentsModule {}
